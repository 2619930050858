export default class Core {
    listeners = [];

    subscribe (event, fn) {
        if (!this.listeners[event]) {
            this.listeners[event] = []
        }

        this.listeners[event].push(fn)
    }

    listener (event) {
        if (!this.listeners[event]) {
            throw new Error(`The event ${event}-listener does not exist`)
        }
    }
}
