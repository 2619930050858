import { main } from '../../iife'

main.subscribe('load', e => {

    const ImagetabsExist = document.querySelector(".imagetabs");
    if( typeof(ImagetabsExist) !== 'undefined' && ImagetabsExist !== null ) {

        const ImagetabsCE = document.querySelectorAll(".imagetabs");
        ImagetabsCE.forEach(imagetabCE => {
            imagetabCE.querySelector(".tabtitle").classList.add("tabActive");
            imagetabCE.querySelector(".imagetab").classList.remove("tabHide");

            // Tabtitle
            const TitleAll = imagetabCE.querySelectorAll(".tabtitle");
            TitleAll.forEach(title => {
                title.addEventListener("click", function() {
                    const Titles = imagetabCE.querySelectorAll(".tabtitle");
                    Titles.forEach(e => {
                        e.classList.remove("tabActive");
                    })
                    // Remove title styling
                    title.classList.remove("tabActive");
                    // title styling
                    title.classList.add("tabActive");

                })
            })

            // Tabcontent        
            TitleAll.forEach(title => {
                title.addEventListener("click", function() {
                    const Imagetabs = imagetabCE.querySelectorAll(".imagetab");
                    Imagetabs.forEach(imagetab => {

                        let TitleValue = imagetabCE.querySelector(".tabtitle.tabActive").getAttribute("value");
                            
                        let TabValue = imagetab.getAttribute('value');
                        if ( TabValue != TitleValue) {
                            imagetab.classList.add("tabHide");
                        } else {
                            imagetab.classList.remove("tabHide");
                        }
                    })
                });
            })

            // Responsive
            imagetabCE.querySelector(".ResponsiveTabheaders .Resptabtitle").classList.add("tabActive");

            imagetabCE.querySelector(".ResponsiveTabheaders").addEventListener("change", function() {
                // Tabheaders
                const SelectValue = imagetabCE.querySelector(".ResponsiveTabheaders").value;
                const SelectOptions = imagetabCE.querySelectorAll(".ResponsiveTabheaders .Resptabtitle");

                SelectOptions.forEach(option => {
                    option.classList.remove("tabActive");
                    if (option.value == SelectValue) {
                        option.classList.add("tabActive");
                    }
                })
                // Imagetabs
                const Imagetabs = imagetabCE.querySelectorAll(".imagetab");
                const CurrentHeaderValue = imagetabCE.querySelector(".ResponsiveTabheaders").value;
                    
                Imagetabs.forEach(tab => {
                    let TabValue = tab.getAttribute('value');
                    if ( TabValue != CurrentHeaderValue) {
                        tab.classList.add("tabHide");
                    } else {
                        tab.classList.remove("tabHide");
                    }
                });

            });

            //// On resize
            window.onresize = window.onload = function() {   
                // Tabtitle
                const TitleAll = imagetabCE.querySelectorAll(".Resptabtitle");
                TitleAll.forEach(title => {

                    title.addEventListener("click", function() {

                        const Titles = imagetabCE.querySelectorAll(".Resptabtitle");
                        Titles.forEach(e => {
                            e.classList.remove("tabActive");
                        })
                        // Remove title styling
                        title.classList.remove("tabActive");
                        // title styling
                        title.classList.add("tabActive");
                    })
                })

                // Tabcontent        
                TitleAll.forEach(title => {
                    title.addEventListener("click", function() {

                        const Imagetabs = imagetabCE.querySelectorAll(".imagetab");
                        Imagetabs.forEach(imagetab => {

                            let TabValue = imagetab.getAttribute('value');
                            const TitleValue = imagetabCE.querySelector(".tabActive").getAttribute('value');

                            if ( TabValue != TitleValue) {
                                imagetab.classList.add("tabHide");
                            } else {
                                imagetab.classList.remove("tabHide");
                            }
                        })
                    });
                })

                // Responsive
                imagetabCE.querySelector(".ResponsiveTabheaders .Resptabtitle").classList.add("tabActive");

                imagetabCE.querySelector(".ResponsiveTabheaders").addEventListener("click", function() {
                    // Tabheaders
                    const SelectValue = imagetabCE.querySelector(".ResponsiveTabheaders").value;
                    const SelectOptions = imagetabCE.querySelectorAll(".ResponsiveTabheaders .Resptabtitle");

                    SelectOptions.forEach(option => {
                        option.classList.remove("tabActive");
                        if (option.value == SelectValue) {
                            option.classList.add("tabActive");
                        }
                    })
                    // Imagetabs
                    const Imagetabs = imagetabCE.querySelectorAll(".imagetab");
                    const CurrentHeaderValue = imagetabCE.querySelector(".ResponsiveTabheaders").value;
                        
                    Imagetabs.forEach(tab => {
                        let TabValue = tab.getAttribute('value');
                        if ( TabValue != CurrentHeaderValue) {
                            tab.classList.add("tabHide");
                        } else {
                            tab.classList.remove("tabHide");
                        }
                    });
                });
            }
        })
    }
})

export default main

