import { main } from '../../iife'

main.subscribe('load', e => {
    var RespNav = document.getElementById("ResponsiveNavigation");
    if( typeof(RespNav) !== 'undefined' && RespNav !== null ) {
    
   
        // Open Respnav
        document.querySelector("#ResponsiveNavigation .NavButton").addEventListener("click", function() {
            document.querySelector(".RespNavBackground").classList.add("ShowNav");
            document.querySelector("body").classList.add("NoScroll")
            document.querySelector("html").classList.add("NoScroll")
        })

        // Close Respnav
        document.querySelector("#ResponsiveNavigation .RespNavClose svg").addEventListener("click", function() {
            document.querySelector(".RespNavBackground").classList.remove("ShowNav");
            document.querySelector("body").classList.remove("NoScroll")
            document.querySelector("html").classList.remove("NoScroll")
        })
    }

    let RespNavSubpages = document.querySelectorAll(".resp-subpages");
    if (typeof(RespNavSubpages) !== 'undefined' && RespNavSubpages !== null) {

        RespNavSubpages.forEach(e => {
            //Remove link function
            e.previousElementSibling.href="javascript:void(0);";

            //Show arrow
            e.previousElementSibling.classList.toggle("showArrow");

            //Show subpages on click and rotate arrow svg
            e.previousElementSibling.addEventListener("click", function(){
                e.classList.toggle("hidden");
                e.previousElementSibling.classList.toggle("rotateArrow");
            });
        });
    }
})

export default main
