import { main } from '../../iife'

main.subscribe('load', e => {

    const Col6elements = document.querySelectorAll(".col6element");

    Col6elements.forEach(element => {

        let elTop = window.pageYOffset + element.getBoundingClientRect().top - window.innerHeight;
        let elBottom = elTop + element.getBoundingClientRect().height + window.innerHeight;

        if (window.pageYOffset > elTop && window.pageYOffset < elBottom ) {
            Col6Animation();
        }   else {
            if (window.pageYOffset < elTop) {
                RemoveCol6Animation();
            }
        }

        document.addEventListener("scroll", function() {
            if (window.pageYOffset  > elTop + 400 && window.pageYOffset < elBottom  ) {
                Col6Animation();
            }   else {
                if (window.pageYOffset < elTop) {
                    RemoveCol6Animation();
                }
            }
        })

        function Col6Animation() {
            const Items = document.querySelectorAll(".element");
            Items.forEach((item, i) => {

                setTimeout(() => {
                    item.classList.add( 'slideUp' );
                }, i * 200);
            })
        };

        function RemoveCol6Animation() {
            const Items = document.querySelectorAll(".element");
            Items.forEach((item, i) => {

                setTimeout(() => {
                    item.classList.remove( 'slideUp' );
                }, i * 200);
            })
        };
       
    });
})

export default main
