import Swiper, { Navigation, Pagination, Autoplay} from 'swiper';

import { main } from '../../iife'

main.subscribe('load', e => {
    const Quoteslider = document.getElementById("Quoteslider");
    if( typeof(Quoteslider) !== 'undefined' && Quoteslider !== null ) {

        const swiper = new Swiper('#Quoteslider', {
            modules: [Navigation, Pagination, Autoplay],
            slidesPerView: 1,
            direction: 'horizontal',
            speed: 1000,
            loop: true,

            autoplay: {
                delay: 7000,
                disableOnInteraction: false,
            },
            
            pagination: {
                el: '.swiper-pagination',
                clickable: true,
            },
        });
    }
})

export default main
