
import { main } from '../../iife'

main.subscribe('load', e => {
    const Lmenu = document.getElementById("language_menu");
    if( typeof(Lmenu) !== 'undefined' && Lmenu !== null ) {
    
        Lmenu.addEventListener("click", function() {
            let element = document.querySelector(".inactive");
            if( element.classList.contains('open')) {
                element.classList.remove("open");
            }   else {
                element.classList.add("open");
            }
        });
    }
})

export default main

