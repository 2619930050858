import { main } from '../../iife'

main.subscribe('load', e => {

    const tabs = document.querySelector(".tabs");
    if( typeof(tabs) !== 'undefined' && tabs !== null ) {

        const Tabs = document.querySelectorAll(".tabs");
        Tabs.forEach(tabCE => {
            // Show First Tab
            tabCE.querySelector(".tabtitle").classList.add("tabActive");
            tabCE.querySelector(".tab").classList.remove("tabHide");

            // Tabtitle
            const TitleAll = tabCE.querySelectorAll(".tabtitle");
            TitleAll.forEach(title => {

                title.addEventListener("click", function() {

                    const Titles = tabCE.querySelectorAll(".tabtitle");
                    Titles.forEach(e => {
                        e.classList.remove("tabActive");
                    })

                    // Remove title styling
                    title.classList.remove("tabActive");
                    // title styling
                    title.classList.add("tabActive");

                })
            })


            // Tabcontent        
            TitleAll.forEach(title => {
                title.addEventListener("click", function() {

                    const Tabs = tabCE.querySelectorAll(".tab");
                    Tabs.forEach(tab => {

                        let TabValue = tab.getAttribute('value');
                        let TitleValue = tabCE.querySelector(".tabActive").getAttribute('value');

                        if ( TabValue != TitleValue) {
                            tab.classList.add("tabHide");
                        } else {
                            tab.classList.remove("tabHide");
                        }
                    })
                });
            })
        })

        
    }
})

export default main

