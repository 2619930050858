import { main } from '../../iife'

main.subscribe('load', e => {

    const Teasertexts = document.querySelectorAll(".teasertext");

    Teasertexts.forEach(teaser => {

        let elTop = window.pageYOffset + teaser.getBoundingClientRect().top - window.innerHeight;
        let elBottom = elTop + teaser.getBoundingClientRect().height + window.innerHeight;

        if (window.pageYOffset > elTop && window.pageYOffset < elBottom) {
            teaser.querySelector(".container").classList.add( 'slideUp' );
        }   else {
            if (window.pageYOffset < elTop) {
                teaser.querySelector(".container").classList.remove( 'slideUp' );
            }
        }

        document.addEventListener("scroll", function() {
            if (window.pageYOffset > elTop && window.pageYOffset < elBottom) {
                teaser.querySelector(".container").classList.add( 'slideUp' );
            } else {
                if (window.pageYOffset < elTop) {
                    teaser.querySelector(".container").classList.remove( 'slideUp' );
                }
            }
        })
       
    });
})

export default main
