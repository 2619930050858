import { main } from '../../iife'

main.subscribe('load', e => {

    const Textimages = document.querySelectorAll(".textimage");

    Textimages.forEach(textimg => {

        let elTop = window.pageYOffset + textimg.getBoundingClientRect().top - window.innerHeight;
        let elBottom = elTop + textimg.getBoundingClientRect().height + window.innerHeight;

        if (window.pageYOffset -400 > elTop && window.pageYOffset < elBottom) {
            textimg.querySelector(".absoluteText").classList.add( 'slideUp' );
        }   else {
            if (window.pageYOffset < elTop) {
                textimg.querySelector(".absoluteText").classList.remove( 'slideUp' );
            }
           
        }

        document.addEventListener("scroll", function() {
            if (window.pageYOffset - 400 > elTop && window.pageYOffset < elBottom) {
                textimg.querySelector(".absoluteText").classList.add( 'slideUp' );
            }   else {
                if (window.pageYOffset < elTop) {
                    textimg.querySelector(".absoluteText").classList.remove( 'slideUp' );
                }
            }
        })
       
    });
})

export default main
