import { main } from '../../iife'

main.subscribe('load', e => {
    var Acc = document.querySelector(".accordion");
    if( typeof(Acc) !== 'undefined' && Acc !== null ) {

        const Accordions = document.querySelectorAll(".accordion");
        Accordions.forEach(accordionCE => {
    
            const AccordionItem = accordionCE.querySelectorAll(".accordion_item");
            AccordionItem.forEach(item => {
                let AccHeadline = item.querySelector("h4")
                let AccText = item.querySelector(".accText")
            
                item.querySelector("h4").addEventListener('click', function() {
            
                    if (AccText.classList.contains("TextShow"))     {
                        AccHeadline.classList.remove("active");
                        AccText.classList.remove("TextShow");
                    }   else {
                        AccHeadline.classList.add("active");
                        AccText.classList.add("TextShow");
                    } 
                })
            })
        })
    }
})

export default main
