import Swiper, { Navigation, Pagination, Autoplay} from 'swiper';

import { main } from '../../iife'

main.subscribe('load', e => {

    const Tileslider = document.getElementById("Tileslider");
    if( typeof(Tileslider) !== 'undefined' && Tileslider !== null ) {

        const swiper = new Swiper('#Tileslider', {
            modules: [Navigation, Pagination, Autoplay],
            slidesPerView: 1,
            spaceBetween: 60,
            direction: 'horizontal',
            speed: 8000,
            loop: true,

            autoplay: {
                delay: 2000,
                disableOnInteraction: true,
            },

            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
            
            },
            pagination: {
                el: '.swiper-pagination',
                clickable: true,
            },
            breakpoints: {
                768: {
                    slidesPerView: 2,
                spaceBetween: 20,
                },
                1200: {
                slidesPerView: 3,
                spaceBetween: 20,
                },
            }
        });
    }
})

export default main
