import Swiper, { Navigation, Pagination, Autoplay} from 'swiper';

import { main } from '../../iife'

main.subscribe('load', e => {

    const Teaserslider = document.getElementById("Teaserslider");
    if( typeof(Teaserslider) !== 'undefined' && Teaserslider !== null ) {

        const swiper = new Swiper('#Teaserslider', {
            modules: [Navigation, Pagination, Autoplay],
            slidesPerView: 1,
            direction: 'horizontal',
            speed: 1000,
            loop: true,

            autoplay: {
                delay: 7000,
                disableOnInteraction: false,
            },

            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
            
            },
            pagination: {
                el: '.swiper-pagination',
                clickable: true,
            },
        });
    }
})

export default main
