import { main } from '../../iife'

main.subscribe('load', e => {

    const Twopics = document.querySelectorAll(".Twopic");

    Twopics.forEach(twopic => {

        let elTop = window.pageYOffset + twopic.getBoundingClientRect().top - window.innerHeight;
        let elBottom = elTop + twopic.getBoundingClientRect().height + window.innerHeight;

        if (window.pageYOffset - 400 > elTop && window.pageYOffset < elBottom) {
            TwoPicAnimation();
        }   else {
            if (window.pageYOffset < elTop) {
                RemoveTwoPicAnimation();
            }
        }

        document.addEventListener("scroll", function() {
            if (window.pageYOffset - 400 > elTop && window.pageYOffset < elBottom) {
                TwoPicAnimation();
            }   else {
                if (window.pageYOffset < elTop) {
                    RemoveTwoPicAnimation();
                }
            }
        })

        function TwoPicAnimation() {
            const Items = document.querySelectorAll(".TwoPicContent");
            Items.forEach((item, i) => {

                setTimeout(() => {

                    item.classList.add( 'slideUp' );

                    setTimeout(() => {
                        item.querySelector(".Twopic-button").classList.add( 'scaleUp' );
                    }, i * 600);

                }, i * 200);
            })
        };

        function RemoveTwoPicAnimation() {
            const Items = twopic.querySelectorAll(".TwoPicContent");
            Items.forEach((item, i) => {

                setTimeout(() => {

                    item.classList.remove( 'slideUp' );

                    setTimeout(() => {
                        item.querySelector(".Twopic-button").classList.remove( 'scaleUp' );
                    }, i * 600);

                }, i * 200);
            })
        };
       
    });
})

export default main
